import React, { useState, useEffect, Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { Modal } from '@redq/reuse-modal';
import { appTheme } from 'common/theme/app';
import {
  GlobalStyle,
  AppWrapper,
  ConditionWrapper,
} from 'containers/App/app.style';
import { ResetCSS } from 'common/assets/css/style';
import Navbar from 'containers/App/Navbar';
import DomainSection from 'containers/App/Banner';
import DomainSectionTitle from 'containers/App/BannerTitle';
import FeatureSection from 'containers/App/FeatureSection';
import ControllSection from 'containers/App/Control';
import TestimonialSection from 'containers/App/Testimonial';
import PartnerHistory from 'containers/App/PartnerHistory';
import PaymentSection from 'containers/App/PaymentSection';
import Footer from 'containers/App/Footer';
import FeatureSlider from 'containers/App/FeatureSlider';
import FeatureSliderTwo from 'containers/App/FeatureSliderTwo';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import '@redq/reuse-modal/es/index.css';
import Helmet from 'react-helmet';
import SEO from 'components/seo';
import Banner from 'containers/Interior/Banner';
import {
  InteriorWrapper,
  ContentWrapper,
} from 'containers/Interior/interior.style';
function getSize() {
  checkApp();
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

const devicesFound = [];

async function checkApp() {
  const nav = window['navigator'];
  const relatedApps = await nav.getInstalledRelatedApps();
  relatedApps.forEach((app) => {
    //if your PWA exists in the array it is installed
    console.log(app.platform, app.url);
    devicesFound.push(app)
    this.isInstalled = true;
  });
}

function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

export default () => {
  const size = process.browser && useWindowSize();
  const innerWidth = process.browser && size.innerWidth;

  return (
    <ThemeProvider theme={appTheme}>
      <Fragment>
        <Helmet>
        </Helmet>
        <SEO title="App to track the Movies you are watching" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <DomainSection />
          <DomainSectionTitle />
          <InteriorWrapper>
            <ContentWrapper>
              <Banner />
            </ContentWrapper>
          </InteriorWrapper>
          {/* <FeatureSection />
          <ControllSection />
          <ConditionWrapper id="keyfeature">
            {innerWidth > 1100 ? <FeatureSlider /> : <FeatureSliderTwo />}
          </ConditionWrapper>
          <PartnerHistory />
          <PaymentSection />
          <TestimonialSection /> */}
          <div style={{fontSize: '5px', color: 'gray'}}>{devicesFound.length > 0 ? '.' : ''}</div>
          {/* <div style={{fontSize: '10px', color: 'gray'}}>{devicesFound.length > 0 ? JSON.stringify(devicesFound) : ''}</div> */}
          <Footer />
        </AppWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
